<template>
    <div>
        <b-overlay :show="loading">
            <filtros-reportes reportType="interno" :tutor="tutor" :inscription-type="true" @buscar="buscar"></filtros-reportes>
            <b-card>
                <h4 class="card-title">Reportes Internos</h4>
                <div class="demo-inline-spacing" v-if="showColumn">
                    <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-1"
                            @click="columnsDefault"
                    >
                        Columnas por Defecto
                    </b-button>
                    <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="outline-primary"
                            @click="checkAllColumns"
                    >
                        <feather-icon
                                icon="check"
                                class="mr-50"
                        />
                        <span class="align-middle">Todas las Columnas</span>
                    </b-button>
                </div>
                <div class="demo-inline-spacing ml-1" v-if="showColumn">

                  <b-form-checkbox
                          v-for="field in fields"
                          v-model="field.display"
                          name="flavour-4a"
                          inline
                  >
                    {{ field.label }}
                  </b-form-checkbox>
                </div>
              <b-row class="pr-1 pl-1">
                  <b-col sm="12" md="12" class="my-1 text-left">
                      <div class="d-flex justify-content-end">
                      <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="outline-primary"
                              @click="showColumns"
                      >
                          <feather-icon v-if="!showColumn"
                                  icon="EyeIcon"
                                  class="mr-50"
                          />
                          <feather-icon v-if="showColumn"
                                  icon="EyeOffIcon"
                                  class="mr-50"
                          />
                          <span class="align-middle">{{showColumnText}}</span>

                      </b-button>
                          <b-button
                                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                  variant="outline-primary"
                                  type="submit"
                                  class="ml-1"
                                  @click="exportar"
                          >
                              <feather-icon
                                      icon="DownloadIcon"
                                      class="mr-50"
                              />
                              <span class="align-middle">Exportar</span>

                          </b-button>
                      </div>
                  </b-col>
              </b-row>
                <b-row class="pl-1">
                    <b-col sm="3" md="4"  class="mt-2">
                        <div class="d-flex align-items-center mb-1 mb-md-0">
                            <label>Mostrar</label>
                            <b-form-select
                                    @change="onChangePerPage"
                                    id="perPageSelect"
                                    v-model="perPage"
                                    :options="pageOptions"
                                    size="sm"
                                    class="mx-50 col-md-2"
                            ></b-form-select>
                            <label>filas por página</label>
                        </div>
                    </b-col>
                    <b-col  cols="4" class="mt-2">
                        <div v-if="totalRows > 0">
                            <span class="text-muted">  Mostrando del {{from}} al {{to}} de {{totalRows}} filas</span>
                        </div>
                    </b-col>

                    <b-col cols="4">
                        <b-pagination
                                @change="onPageChange"
                                :total-rows="totalRows"
                                :value="page"
                                :per-page="perPage"
                                align="right"
                                class="mt-2 mr-1"
                                aria-controls="my-table"
                        />
                    </b-col>

                </b-row>
                <b-col cols="12" v-if="showTable">
                    <div class="d-none">{{mensaje}}</div>
                    <b-table
                            striped
                            hover
                            small
                            :per-page="perPage"
                            :items="listado"
                            :fields="filteredHeaders"
                            responsive
                            class="mb-0"
                            id="my-table"
                            ref="table"
                            show-empty
                    >
                        <template #cell()="data">
                            {{ data.value ? data.value : '-' }}
                        </template>

                            <template #cell(telefono_contacto)="data">
                                <div class="text-center">
                                    {{ data.item.telefono_contacto == 1 ? "Si" : "No" }}
                                </div>
                            </template>
                            <template #cell(fecha_inicio)="data">
                                <b-badge variant="success">
                                    {{ data.item.fecha_inicio  ? data.item.fecha_inicio : '-'}}
                                </b-badge>
                            </template>
                            <template #cell(fecha_cierre)="data">
                                <b-badge variant="success">
                                    {{ data.item.fecha_cierre  ? data.item.fecha_cierre : '-'}}
                                </b-badge>
                            </template>
                            <template #cell(porciento_avance)="data">
                                <div class="text-center">
                                    <b-badge class="center" :variant="porciento_avance(data.item.porciento_avance)">{{ data.item.porciento_avance  ? data.item.porciento_avance : '-'}}</b-badge>
                                </div>
                            </template>
                            <template #cell(evaluacion_final_fecha)="data">
                                <b-badge v-if="data.item.evaluacion_final_fecha" variant="success">
                                    {{ data.item.evaluacion_final_fecha}}
                                </b-badge>
                                <div v-if="!data.item.evaluacion_final_fecha">-</div>
                            </template>
                            <template #cell(ultimo_acceso)="data">
                                <b-badge v-if="data.item.ultimo_acceso" variant="success">
                                    {{ data.item.ultimo_acceso}}
                                </b-badge>
                                <div v-if="!data.item.ultimo_acceso">-</div>
                            </template>
                            <template #cell(detalle_seguimiento)="data">
                               {{ data.item.detalle_seguimiento  ? '-' : '-'}}
                           </template>
                        <template #empty="scope">
                            <div class="text-center">No existen resultados</div>
                        </template>
                    </b-table>

                </b-col>

            </b-card>
        </b-overlay>

        <b-modal ref="modalExport"
                 title="Información"
                 ok-only
                 ok-title="Cerrar">
            <p class="my-4"><b>Procesando el reporte</b>! Recibirá un correo con el enlace para descargar el informe</p>
        </b-modal>
    </div>
</template>

<script>
    import FiltrosReportes from '@/components/FiltrosReportes.vue'
    import {
        BTable,
        BProgress,
        BBadge,
        BPagination,
        BRow,
        BCol,
        BFormSelect,
        BTfoot,
        BTr,
        BTh,
        BFormCheckbox,
        BButton,
        BModal,
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import ToastificationContent from "@core/components/toastification/ToastificationContent";
    export default {
        name: 'ReportesInternos',
        components: {
            BTable,
            BProgress,
            BBadge,
            BPagination,
            BRow,
            BCol,
            BFormSelect,
            BTfoot,
            BTr,
            BTh,
            BFormCheckbox,
            BButton,
            BModal,
            'filtros-reportes': FiltrosReportes,
        },
        directives: {
            Ripple,
        },
        data() {
            return {
                //loading: false,
                showTable: true,
                tutor: true,
                perPage: 50,
                reportType: 1,
                currentPageV: 1,
                page: 1,
                list:[],
                filter: {
                    fechaDesde: null,
                    fechaHasta: null,
                    type: 3,
                    inscription: 1,
                    ficha: null,
                    ordenCompra: null,
                    aplicacion: null,
                    holding: null,
                    ejecutivo: null,
                    cliente: null,
                    alumno: null,
                    tutor: null,
                    fields:[]
                },
                sortField: 'nombre',
                sortOrder: 'desc',
                defaultSortOrder: 'desc',
                sortDesc: false,
                showColumn: false,
                showColumnText: 'Mostrar Columnas',
                fields: [
                    {key: 'num_ficha', label: 'Ficha', display: true},
                    {key: 'num_orden_compra', label: 'OC ', display: true},
                    {key: 'nombre', label: 'Nombre', display: true},
                    {key: 'apellidos', label: 'Apellidos', display: true},
                    {key: 'rut', label: 'Rut', display: true},
                    {key: 'correo1', label: 'Correo1', display: true},
                    {key: 'correo2', label: 'Correo2', display: true},
                    {key: 'correo_personal', label: 'Correo Personal', display: false},
                    {key: 'correo_institucional', label: 'Correo Institucional', display: false},
                    {key: 'fono1', label: 'Fono 1', display: false},
                    {key: 'fono2', label: 'Fono 2', display: false},
                    {key: 'telefono_curso', label: 'Teléfono(Por Curso)', display: true},
                    {key: 'celular_curso', label: 'Celular(Por Curso)', display: false},
                    {key: 'telefono_contacto', label: 'Teléfono Contacto', display: true},
                    {key: 'aplicacion', label: 'Curso', display: false},
                    {key: 'id_course_in_platform', label: 'ID Curso', display: false},
                    {key: 'horas_curso', label: 'Horas Curso', display: false},
                    {key: 'nombre_corto', label: 'Nombre Corto Curso', display: true},
                    {key: 'fecha_inicio', label: 'Fecha Inicio ', display: true},
                    {key: 'fecha_cierre', label: 'Fecha Cierre', display: true},
                    {key: 'modality', label: 'Modalidad', display: true},
                    {key: 'tutor_asignado', label: 'Asignado(Tutor)', display: true},
                    {key: 'razon_social_empresa', label: 'Empresa', display: true},
                    {key: 'nombre_holding', label: 'Holding', display: false},
                    {key: 'sucursal', label: 'Sucursal', display: true},
                    {key: 'ejecutivo_empresa_nombrecompleto_usuario', label: 'Ejecutivo', display: true},
                    {key: 'usuario_ingreso_nombre', label: 'BackOffice', display: true},
                    {key: 'porciento_avance', label: '% Avance', display: true},
                    {key: 'finalizado', label: 'Finalizado', display: true},
                    {key: 'porciento_evaluaciones_realizadas', label: '% Evaluaciones Realizadas', display: false},
                    {key: 'evaluacion_final_fecha', label: 'Evaluacion Final Fecha', display: true},
                    {key: 'evaluacion_final_nota', label: 'Evaluacion Final Nota', display: true},
                    {key: 'ultimo_acceso', label: 'Último Acceso', display: false},
                    {key: 'cat2_accion_realizada', label: 'Cat.2(Acción realizada)', display: false},
                    {key: 'cat4_resultado', label: 'Cat.4(Resultado)', display: false},
                    {key: 'fecha_ultimo_seguimiento', label: 'Fecha último seguimiento', display: true},
                    {key: 'cantidad_seguimiento', label: 'Cantidad Seguimiento', display: true},
                    {key: 'detalle_seguimiento', label: 'Detalle seguimiento (fecha y comentario)', display: true},
                    {key: 'registro_sence', label: 'Registro Sence', display: true},
                    {key: 'estado_dj', label: 'Estado Dj', display: true},
                    {key: 'area', label: 'Area', display: false},
                    {key: 'registro_historico', label: 'Registro Historico', display: true},
                    {key: 'estado_inscripcion', label: 'Estado Inscripción', display: true},
                    {key: 'descripcion_centro_costo', label: 'Descripción Centro Costo', display: false},
                    {key: 'tbl_course_version_complete', label: 'Curso Versión Completa', display: false}
                ],
                pageOptions: [50, 100, 500, 1000],
                transProps: {
                    // Transition name
                    name: 'flip-list'
                },
            }
        },

        mounted() {
            this.getList();
            // Set the initial number of items
            //this.totalRows = this.items.length;
        },
        methods: {
            buscar(filter) {
                this.filter = filter;
                this.getList();
            },
            onPageChange(page) {
                this.page = page;
                this.getList();
            },
            onChangePerPage() {
                this.page = 1;
                this.getList();
            },
            getList() {
                let filterData = {
                    'type': this.filter.type,
                    'num_ficha': this.filter.ficha,
                    'num_orden_compra': this.filter.ordenCompra,
                    'fecha_desde': this.filter.fechaDesde,
                    'fecha_hasta': this.filter.fechaHasta,
                    'id_holding': this.filter.holding,
                    'id_empresa': this.filter.cliente,
                    'id_aplicacion': this.filter.aplicacion,
                    'id_usuario': this.filter.ejecutivo,
                    'alumno': this.filter.alumno,
                    'tutor': this.filter.tutor,
                    'sort_by': this.sortField,
                    'page': this.page,
                    'perPage': this.perPage,
                    'inscription': this.filter.inscription,
                }
                this.$store.dispatch('reportesInternos/getList', filterData)
            },
            showColumns() {
                this.showColumn = this.showColumn == true ? false : true;
                this.showColumnText = this.showColumn == true ? 'Ocultar Columnas' : 'Mostrar Columnas';
            },
            columnsDefault() {
                this.fields = [
                    {key: 'num_ficha', label: 'Ficha', display: true},
                    {key: 'num_orden_compra', label: 'OC ', display: true},
                    {key: 'nombre', label: 'Nombre', display: true},
                    {key: 'apellidos', label: 'Apellidos', display: true},
                    {key: 'rut', label: 'Rut', display: true},
                    {key: 'correo1', label: 'Correo1', display: true},
                    {key: 'correo2', label: 'Correo2', display: true},
                    {key: 'correo_personal', label: 'Correo Personal', display: false},
                    {key: 'correo_institucional', label: 'Correo Institucional', display: false},
                    {key: 'fono1', label: 'Fono 1', display: false},
                    {key: 'fono2', label: 'Fono 2', display: false},
                    {key: 'telefono_curso', label: 'Teléfono(Por Curso)', display: true},
                    {key: 'celular_curso', label: 'Celular(Por Curso)', display: false},
                    {key: 'telefono_contacto', label: 'Teléfono Contacto', display: true},
                    {key: 'aplicacion', label: 'Aplicacion', display: false},
                    {key: 'horas_curso', label: 'Horas Curso', display: false},
                    {key: 'nombre_corto', label: 'Nombre Corto Curso', display: true},
                    {key: 'fecha_inicio', label: 'Fecha Inicio ', display: true},
                    {key: 'fecha_cierre', label: 'Fecha Cierre', display: true},
                    {key: 'modality', label: 'Modalidad', display: true},
                    {key: 'tutor_asignado', label: 'Asignado(Tutor)', display: true},
                    {key: 'razon_social_empresa', label: 'Empresa', display: true},
                    {key: 'nombre_holding', label: 'Holding', display: false},
                    {key: 'sucursal', label: 'Sucursal', display: true},
                    {key: 'ejecutivo_empresa_nombrecompleto_usuario', label: 'Ejecutivo', display: true},
                    {key: 'usuario_ingreso_nombre', label: 'BackOffice', display: true},
                    {key: 'porciento_avance', label: '% Avance', display: true},
                    {key: 'finalizado', label: 'Finalizado', display: true},
                    {key: 'porciento_evaluaciones_realizadas', label: '% Evaluaciones Realizadas', display: false},
                  {key: 'evaluacion_final_fecha', label: 'Evaluacion Final Fecha', display: true},
                  {key: 'evaluacion_final_nota', label: 'Evaluacion Final Nota', display: true},
                    {key: 'ultimo_acceso', label: 'Último Acceso', display: false},
                    {key: 'cat2_accion_realizada', label: 'Cat.2(Acción realizada)', display: false},
                    {key: 'cat4_resultado', label: 'Cat.4(Resultado)', display: false},
                    {key: 'fecha_ultimo_seguimiento', label: 'Fecha último seguimiento', display: true},
                    {key: 'cantidad_seguimiento', label: 'Cantidad Seguimiento', display: true},
                    {key: 'detalle_seguimiento', label: 'Detalle seguimiento (fecha y comentario)', display: true},
                    {key: 'registro_sence', label: 'Registro Sence', display: true},
                    {key: 'estado_dj', label: 'Estado Dj', display: true},
                    {key: 'area', label: 'Area', display: false},
                    {key: 'registro_historico', label: 'Registro Historico', display: true},
                    {key: 'estado_inscripcion', label: 'Estado Inscripción', display: true},
                    {key: 'descripcion_centro_costo', label: 'Descripción Centro Costo', display: false},
                    {key: 'tbl_course_version_complete', label: 'Curso Versión Completa', display: false}
                ]
            },
            checkAllColumns() {
                for (let key in this.fields) {
                    this.fields[key].display = true;
                }
            },
            porciento_avance(value) {
                const number = parseFloat(value)
                if (number < 50) {
                    return 'danger'
                } else if (number >= 50 && number < 100) {
                    return 'warning'
                } else if (number >= 100) {
                    return 'success'
                }
            },
            exportar() {
                console.log('this.filter',this.filter)
                if (this.listado.length == 0) {
                    this.$bvToast.toast('Debe realizar una búsqueda antes de exportar', {
                        title: `ALERTA!!`,
                        variant: 'danger',
                        solid: true,
                    })
                }
                if(this.filter.cursoActivo == 0 && (this.filter.aplicacion == null
                    && this.filter.empresa == null
                    && this.filter.holding == null
                    && this.filter.ficha == null
                    && this.filter.ordenCompra == null
                    && this.filter.cliente == null
                ))
                {

                    this.$bvToast.toast('Al exportar Cursos No Activo debe seleccionar al menos un filtro más', {
                        title: `ALERTA!!`,
                        variant: 'danger',
                        solid: true,
                    })

                    return;
                }else {
                    this.getExportData();
                }
            },
            getExportData() {
                let fieldsV = [];
                Object.entries(this.fields).forEach(entry => {
                    const [key, value] = entry;
                    if (value.display) {
                        fieldsV.push(value.key);
                    }
                });
                console.log(fieldsV)
                this.filter.fields = fieldsV;
                let filterData = {
                    'filter': {
                        'type': this.filter.type,
                        'num_ficha': this.filter.ficha,
                        'num_orden_compra': this.filter.ordenCompra,
                        'fecha_desde': this.filter.fechaDesde,
                        'fecha_hasta': this.filter.fechaHasta,
                        'id_holding': this.filter.holding,
                        'id_empresa': this.filter.cliente,
                        'id_usuario': this.filter.ejecutivo,
                        'id_aplicacion': this.filter.aplicacion,
                        'sort_order': this.sortOrder,
                        'tutor': this.filter.tutor,
                        'fields': fieldsV,
                    },
                    'page': this.page,
                    'perPage': this.perPage,
                    'sort_by': this.sortField,
                }

                if(this.totalRows > 50000 || (this.totalRows > 10000 && fieldsV.find(field => field =="detalle_seguimiento")!=null)){
                   // this.$refs.modalExport.show();
                    this.$store.dispatch('reportesInternos/exportAllData', filterData)
                }else {
                    this.$store.dispatch('reportesInternos/exportData', filterData)
                }
            },
            clear() {
            },
            showToast(variant, mensaje) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notificación',
                        icon: 'BellIcon',
                        text: mensaje,
                        variant,
                    },
                })
            },
        },
        computed: {
            totalRows() {
                return this.$store.state.reportesInternos.rowsTotal;
            },
            to() {
                return this.$store.state.reportesInternos.to;
            },
            from() {
                return this.$store.state.reportesInternos.from;
            },
            listado() {
                return this.$store.state.reportesInternos.list
            },
            currentPage() {
                return this.$store.state.reportesInternos.currentPage
            },
            filteredHeaders() {
                return this.fields.filter(h => h.display === true)
            },
            loading() {
                return this.$store.state.reportesInternos.loading;
            },
            mensaje() {
                let mensaje = this.$store.state.reportesInternos.mensaje;
                if(mensaje !== '' ){
                    this.$refs.modalExport.show();
                    this.$store.commit('reportesInternos/setMensaje', '')
                }
                return mensaje
            },

        },
        watch: {
        }
    }
</script>

<style scoped>

</style>
